import React, {useState} from 'react'
import PhoneInput from 'react-phone-number-input/input'
import * as ReactDOM from "react-dom";


export default function PhoneNumberField(props) {
  const { attrs, name, required, value } = props

  const [phoneNumber, setPhoneNumber] = useState(value)

  return (
    <PhoneInput
      {...attrs}
      name={name}
      onChange={(value) => setPhoneNumber(value)}
      required={required}
      value={phoneNumber}
    />
  )
}

    import { render } from 'react-dom'                                    
    if (window.reactComponents === undefined) {                           
      window.reactComponents = {}                                         
    }                                                                     
    window.reactComponents.PhoneNumberField = (function() {                   
    let _args = {}                                                        
                                                                          
    return {                                                              
      init: function (Args) {                                             
        _args = Args                                                      
      },                                                                  
      render: function () {                                               
        const  { id, ...props } = JSON.parse(_args)                       
        render(<PhoneNumberField {...props}/>, document.getElementById(id))   
      }                                                                   
    }                                                                     
  }())